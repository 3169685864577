.inputModule {
    @apply relative;
}
.formGroup {
    @apply relative mb-6;
}
.darkSearchbox .formGroup {
    @apply mb-0;
}
.noSpace {
    margin-bottom: 0 !important;
}
.formControl {
    @apply h-[36px] w-full rounded-md border border-interface-600 bg-interface-700 px-3 py-1 text-sm text-common-white shadow-input-shadow placeholder:text-interface-400 focus:border-interface-300 focus:outline-none;
}
.formControl.lg {
    @apply h-[46px] px-3 py-[5px] text-base;
}
.formControl.md {
    @apply h-[38px] px-3 py-[4px] text-sm;
}
.formControl.withoutBorder {
    @apply border-none bg-transparent shadow-none focus:shadow-none;
}
.formControl.hasError,
.isFormcontrolError .formControl.hasError {
    @apply border-danger-600 !important;
}
.uploadError .formControl {
    @apply border-danger-600;
}
.formControl.dark {
    @apply border border-interface-600 bg-interface-700 text-common-white;
}
.formControl.medium {
    @apply border-interface-600 bg-interface-800 text-sm text-common-white placeholder:text-interface-400 focus:border-interface-300 focus:shadow-none focus:outline-none;
}
.formControl.light {
    @apply border-interface-600 bg-interface-700 text-base text-interface-400 placeholder:text-interface-400 focus:border-interface-300 focus:shadow-none focus:outline-none;
}
.form-control.dark i {
    @apply text-interface-200;
}
textarea.formControl {
    @apply block h-auto w-full resize-none p-3;
}
.formControl.highlighted {
    @apply bg-interface-50 text-interface-900;
}
.formControl.disabled {
    @apply pointer-events-none opacity-70;
}
.no-form-space {
    @apply mb-0;
}
.datetime {
    @apply relative inline-block w-full;
}
.datetime .form-control {
    @apply w-full;
}
.dataPickerRange .startDate .formControl.sm,
.dataPickerRange .endDate .formControl.sm {
    @apply h-9;
}
.phoneInput {
    @apply relative;
}
.phoneInput .flagIcon {
    @apply absolute bottom-0 left-3 top-9 text-lg;
}
.datetime .icon-calendar {
    @apply relative top-[-10px];
}
.dataPickerRange {
    @apply flex;
}
.dataPickerRange .startDate,
.dataPickerRange .endDate {
    @apply w-2/4;
}
.dataPickerRange .startDate .formControl {
    @apply rounded-tr-none rounded-br-none;
}
.dataPickerRange .endDate .formControl {
    @apply ml-[-1px] rounded-tl-none rounded-bl-none;
}
.searchInput {
    @apply relative inline-block w-full ipad:max-w-none;
}
.searchInput .formGroup {
    @apply mb-0;
}
.searchInput input.formControl {
    @apply h-[36px] pb-[6px];
}
.searchInput input {
    @apply mb-0 w-full border-0 bg-interface-700 pl-[34px] text-common-white placeholder:text-interface-300;
}
.form-control .searchInput i {
    @apply text-sm;
}
.darkSearchbox .searchInput i {
    @apply text-interface-200;
}
.thumnailWrapper {
    @apply grid grid-cols-5 gap-4 gap-y-0;
}
.thumnail {
    @apply relative rounded-md bg-theme-dark-100;
}
.configuration-thumnail.configuration-thumnail .thumnail {
    @apply mb-0;
}
.thumnail span {
    @apply overflow-hidden rounded-lg;
}
.deleteImg {
    @apply absolute right-2 top-2 flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-common-white text-common-black;
}
.deleteImgTrash {
    @apply absolute right-2 top-2 flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-live text-common-white;
}
.filePreviewAside {
    @apply flex w-full gap-3;
}
.filePreviewAside .formGroup {
    @apply w-full;
}
.testing.testing .formGroup {
    @apply mb-0;
}
.thumbnailimage {
    @apply h-full w-full object-cover;
}
.customDurationPicker {
    @apply z-30 overflow-visible;
}
.customDurationPicker .icon-section {
    @apply absolute top-0 right-0 flex h-full items-center justify-center px-3;
}
.customDurationPicker input {
    letter-spacing: 3px;
    @apply relative top-0 left-0 h-[40px] w-[34px] min-w-0 text-center  outline-none;
}
.durationDropdown {
    @apply absolute left-[-1px]  top-[45px] h-[150px] w-[115px] rounded-md border border-interface-200 bg-common-white text-center shadow;
}
.dropdownItems li {
    @apply cursor-pointer py-1.5 text-sm;
}
.dropdownItems .selected,
.dropdownItems .active {
    @apply bg-interface-200 font-semibold text-common-black;
}
.bgPlaceholder {
    background-image: url(/storage/user-placeholder.png);
    @apply h-9 w-9 items-center justify-center overflow-hidden rounded-full bg-interface-700 bg-cover bg-center;
}
.custom-selectbox {
    @apply relative;
}
/* switch btn */
.switch {
    @apply relative inline-block h-5 w-8;
}
.switch input {
    @apply h-0 w-0 opacity-0;
}
.slider {
    -webkit-transition: 0.4s;
    transition: 0.4s;
    @apply absolute top-0 bottom-0 left-0 right-0 cursor-pointer bg-interface-500;
}
.slider:before {
    content: '';
    -webkit-transition: 0.4s;
    transition: 0.4s;
    @apply absolute left-0.5 bottom-0.5 h-4 w-4 rounded-full bg-common-white;
}
input:checked + .slider {
    @apply bg-primary-500;
}
input:checked + .slider:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
}
.round {
    @apply rounded-[34px];
}
